import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  threads: [],
  selectedThread: null,
  showExpiredModal: false,
  openPayModal: false,
  showUpgradeModal: false,
  selectedPlan: 'annual',
  openWordModal: false,
  showRoadmapModal: false,
};

const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    getThreads(state) {
      state.loading = true;
    },
    getThreadsSuccess(state, action) {
      state.loading = false;
      state.threads = action.payload.threads;
    },
    getThreadsFailure(state) {
      state.loading = false;
      state.threads = [];
    },
    setSelectedThread(state, action) {
      state.selectedThread = action.payload;
    },
    setShowExpiredModal(state, action) {
      state.showExpiredModal = action.payload;
    },
    initThread(state, action) {
      state.threads = [];
      state.loading = false;
      state.selectedThread = null;
      state.showExpiredModal = false;
    },
    setOpenPayModal(state, action) {
      state.openPayModal = !state.openPayModal;
    },
    setShowUpgradeModal(state, action) {
      state.showUpgradeModal = action.payload;
    },
    setSelectedPlan(state, action) {
      state.selectedPlan = action.payload;
    },
    setOpenWordModal(state, action) {
      state.openWordModal = action.payload;
    },
    setShowRoadmapModal(state, action) {
      state.showRoadmapModal = action.payload;
    },
  },
});

export const {
  getThreads,
  getThreadsSuccess,
  getThreadsFailure,
  setSelectedThread,
  setShowExpiredModal,
  initThread,
  setOpenPayModal,
  setShowUpgradeModal,
  setSelectedPlan,
  setOpenWordModal,
  setShowRoadmapModal,
} = threadSlice.actions;
export default threadSlice.reducer;
