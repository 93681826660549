import { getRequest, postRequest } from './axiosClient';

export const getAllUsers = async (query) => {
  try {
    const response = await getRequest('users', query);
    return response.data;
  } catch (error) {
    console.log('[GET users error]', error);
    return {
      users: [],
      total: 0,
    };
  }
};

export const getDashboardData = (query) => getRequest('users/getDashboardData', query);
export const getCompareData = (query) => getRequest('users/getCompareData', query);
export const updatePaymentEmail = (data) => postRequest('users/updatePaymentEmail', data);
export const getPaymentData = (query) => getRequest('users/getPaymentData', query);
export const confirmPayment = (data) => postRequest('users/confirmPayment', data);
export const saveOnboardingData = (data) => postRequest('users/saveOnboardingData', data);
export const saveNickName = (data) => postRequest('users/saveNickName', data);
export const updateAgreedAt = (data) => postRequest('users/updateAgreedAt', data);