import { useState } from 'react';
import { Modal } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CreditCard, Lock, Shield } from 'lucide-react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CreditCardForm } from './CreditCardForm';
import constants from '../../config/constants';

const stripePromise = loadStripe(constants.stripePK);

const CreditCardModalContent = ({ isOpen, onClose, selectedPlan }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const planDetails = {
    monthly: {
      price: '$19.99/month',
      trial: '7-day free trial, then $19.99/month',
    },
    annual: {
      price: '$14.99/month',
      trial: '7-day free trial, then $179.88/year (save 25%)',
    },
  };

  const handleSubmit = async (formData) => {
    console.log('💳 Processing payment details:', {
      ...formData,
      plan: selectedPlan,
    });
    setIsSubmitting(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      console.log('✅ Payment details processed successfully');
      onClose();
    } catch (error) {
      console.error('❌ Payment processing error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          open={isOpen}
          onCancel={onClose}
          footer={null}
          closable={false}
          classNames={{
            content: '!p-0 !bg-transparent',
            mask: '!bg-black/60 backdrop-blur-sm',
          }}
          centered
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative w-full max-w-lg bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-6 shadow-2xl"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white/60 hover:text-white transition-colors bg-transparent border-none cursor-pointer"
            >
              <X className="w-6 h-6" />
            </button>

            <div className="flex items-center gap-3 mb-6">
              <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center">
                <CreditCard className="w-6 h-6 text-white" />
              </div>
              <div>
                <h2 className="text-2xl font-bold text-white">Set Up Your Free Trial</h2>
                <p className="text-white/80 text-sm">{planDetails[selectedPlan].trial}</p>
              </div>
            </div>

            <CreditCardForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />

            <div className="mt-6 border-t border-white/10 pt-6">
              <div className="flex items-center gap-3 text-white/60 text-sm">
                <Lock className="w-4 h-4" />
                <span>Your payment info is secured with bank-level encryption</span>
              </div>
              <div className="flex items-center gap-3 text-white/60 text-sm mt-2">
                <Shield className="w-4 h-4" />
                <span>No charges during trial • Cancel anytime</span>
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export const CreditCardModal = (props) => (
  <Elements stripe={stripePromise}>
    <CreditCardModalContent {...props} />
  </Elements>
);
