import React from 'react';
import { Button, Modal, Progress, Typography } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const { Title } = Typography;

const SafeModal = ({
  open,
  onClose,
}) => {
  const siderCollapsed = useSelector(state => state.app.siderCollapsed);

  return (
    <Modal centered classNames={{ wrapper: classNames(!siderCollapsed && "!ms-[310px]"), content: "", mask: classNames(!siderCollapsed && "!ms-[310px]") }} open={open} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false} width={600}>
      <div className='text-center'>
        <Progress /* strokeLinecap="butt" */ percent={10} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
        <div className={classNames("flex mt-4 items-stretch gap-4 flex-col")}>
          <div className={classNames("flex-1 flex flex-col justify-around items-center")}>
            <Title level={3} className='!font-extrabold'>We Care About Your Safety</Title>
            <p className='text-center m-0'>
              Thank you for choosing our Al support system. Please note that while our Al is designed to provide guidance and emotional support, it is not a substitute for professional medical or mental health care.
            </p>
            <p className='text-center my-2'>
              If you ever feel overwhelmed, distressed, or are considering harming. yourself or others, we strongly encourage you to reach out to a licensed mental health professional or contact emergency services like 911. Your safety and well-being are our top priority, and it's important to seek real human support when needed.
            </p>
            <p className='text-center m-0'>
              By continuing, you acknowledge that Abby is not a crisis service and agree to use it as a supplementary tool rather than a replacement for professional care.</p>
            <br />
            <Button className={classNames('px-12 font-bold')} size='large' type='primary' onClick={() => {
              onClose();
            }}>I understand</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SafeModal;