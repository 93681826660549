import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

const variants = {
  primary: 'bg-white text-black hover:bg-white/90',
  secondary: 'bg-black/20 text-white hover:bg-black/30',
};

export function Button({ children, variant = 'primary', isLoading = false, disabled, className = '', ...props }) {
  return (
    <motion.button
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.1 }}
      className={`
        px-8 py-3 
        rounded-lg 
        font-semibold 
        transition-colors
        relative
        disabled:opacity-50
        disabled:cursor-not-allowed
        ${variants[variant]}
        ${className}
      `}
      whileHover={disabled ? {} : { scale: 1.02 }}
      whileTap={disabled ? {} : { scale: 0.98 }}
      disabled={isLoading || disabled}
      aria-disabled={isLoading || disabled}
      {...props}
    >
      {isLoading ? (
        <span className="flex items-center justify-center gap-2">
          <Loader2 className="w-5 h-5 animate-spin" />
          {/* Processing... */}
          {children}
        </span>
      ) : null}
      <span className={isLoading ? 'opacity-0 hidden' : 'opacity-100'}>{children}</span>
    </motion.button>
  );
}
