import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { FaRegEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// import AnimationBG from "../pages/Home/Partials/AnimationBG";
import PayModal from '../pages/Home/Partials/PayModal';
import { MenuOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SideBar from './partials/SideBar';
// import Settings from "./partials/Settings";
import UserMenu from './partials/UserMenu';
import LogoSrc from '../../assets/images/Abby_3x.png';
import smLogoSrc from '../../assets/images/Abby_3x.png';
import constants from '../../config/constants';
import { setSiderCollapsed } from '../../redux/app/appSlice';
import { setHasMore, setMessages, setPage } from '../../redux/message/messageSlice';
import { getPlans } from '../../redux/plan/planSlice';
import { getThreads, setOpenPayModal, setSelectedThread } from '../../redux/thread/threadSlice';

import { newThread } from '../../services/threadAPI';

// const { useToken } = theme;
const { Header } = Layout;

const stripePromise = loadStripe(constants.stripePK);

function AuthLayout({ children }) {
  // const { token } = useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const isDarkMode = useSelector((state) => state.app.isDarkMode);
  // const openPayModal = useSelector((state) => state.thread.openPayModal);
  // const user = useSelector((state) => state.auth.user);
  // const plans = useSelector((state) => state.plan.plans ?? []);
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const selectedThread = useSelector((state) => state.thread.selectedThread);
  const isPaid = useSelector(
    (state) =>
      state.auth.user.activeSubscription &&
      (state.auth.user.activeSubscription.status === 'active' ||
        state.auth.user.activeSubscription.status === 'trialing'),
  );

  const [hasSider, setHasSider] = useState(false);

  // const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.includes('playground')) {
      setHasSider(true);
      if (isMobile) {
        dispatch(setSiderCollapsed(true));
      }
    } else {
      setHasSider(false);
    }
  }, [dispatch, location]);

  /*   const changeTheme = () => {
      dispatch(setDarkMode());
    }; */

  /*   const onClose = () => {
      setOpen(false);
    }; */

  return (
    <Layout
      className={classNames(location.pathname.includes('/voice-playground') && 'bg-[#0c111b]', 'flex h-full bg-white')}
      hasSider={hasSider}
    >
      {hasSider && <SideBar />}
      <Layout
        className={classNames(
          hasSider && !siderCollapsed && !isMobile && 'ms-[310px]',
          location.pathname.includes('/voice-playground') && 'bg-[#0c111b]',
          'flex h-full flex-col flex-1 bg-white',
        )}
      >
        <Header
          className={classNames(
            `flex items-center justify-center shadow-sm sticky pr-4 top-0 z-[998]`,
            siderCollapsed && !isMobile ? 'pl-8' : 'pl-0',
            location.pathname === '/voice-playground' ? 'bg-transparent' : 'bg-white',
          )}
        >
          {hasSider && siderCollapsed && (
            <Button
              className="absolute top-4 left-3 z-[1071]"
              onClick={() => {
                dispatch(setSiderCollapsed(!siderCollapsed));
              }}
              icon={siderCollapsed ? <MenuOutlined /> : <MenuOutlined />}
              type="text"
            />
          )}
          {isMobile && (
            <Button
              icon={<FaRegEdit className="text-xl ml-1" />}
              type="text"
              className='opacity-0'
            />
          )}
          <div className={classNames('flex items-center px-2 flex-1', isMobile ? 'justify-center' : 'justify-between')}>
            {
              <div className="flex items-center">
                <div
                  className={classNames(
                    'demo-logo h-[64px]',
                    // !isDarkMode && "bg-white"
                  )}
                >
                  <Link to="/playground" className="hidden sm:inline">
                    <img
                      src={LogoSrc}
                      alt="logo"
                      className={classNames(
                        'w-[96px] p-3 text-white invert',
                        // !isDarkMode && "invert"
                      )}
                    />
                  </Link>
                  <Link to="/playground" className="inline sm:hidden">
                    <img
                      src={smLogoSrc}
                      alt="logo"
                      className={classNames(
                        'w-[96px] p-3 text-white invert',
                        // !isDarkMode && "invert"
                      )}
                    />
                  </Link>
                </div>
              </div>
            }
            {!isMobile && (
              <div className="flex items-center">
                {/* <div>
                <Link to="/playground" className="mr-4 text-primary sm:mr-6">
                  Text Chat
                </Link>
                <Link to="/voice-playground" className="mr-4 text-primary sm:mr-6">
                  Voice Chat
                </Link>
              </div> */}
                {/* <div className="mr-2 sm:mr-6">
                <Button type="primary" icon={<FileAddOutlined />} onClick={() => {
                  // return navigate('/home');
                  if (user.activeSubscription && user.activeSubscription.status == 'active') {
                    return navigate('/home');
                  }
                  if (user.activeSubscription && user.activeSubscription.status != 'active') {
                    Modal.confirm({
                      title: 'Your subscription past due.',
                      icon: <ExclamationCircleFilled />,
                      content: 'Will you continue it?',
                      okText: "Yes",
                      cancelText: "No",
                      onOk() {
                        window.open(user.activeSubscription.hosted_invoice, '_blank');
                        return new Promise((resolve, reject) => {
                          setTimeout(resolve, 1000);
                        }).catch(() => console.log('Oops errors!'));
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    setOpenModal();
                  }
                }}>New Project</Button>
              </div> */}
                {!location.pathname.includes('playground') && <UserMenu placement="header" />}
              </div>
            )}
            {/* {(isMobile && !isPayed) && <div className="leading-[0] w-52 mt-2.5">
              <p className="m-0 text-lg font-semibold text-center animate-shine">{config.steps[step]}</p>
              <Progress className='leading-[0] [&_.ant-progress-outer]:inline-flex' status="active" strokeLinecap="butt" percent={totalMessages / 40 * 100} showInfo={false} size={["100%", 8]} strokeColor="#3b0075" trailColor="#1b0035" />
            </div>} */}
          </div>
          {isMobile && (
            <Button
              icon={<FaRegEdit className="text-xl ml-1" />}
              type="text"
              disabled={!isPaid}
              onClick={() => {
                newThread()
                  .then((res) => {
                    // console.log(res.data);
                    dispatch(setPage(1));
                    dispatch(setHasMore(true));
                    dispatch(setMessages([]));
                    dispatch(getThreads());
                    navigate(location.pathname.replace(selectedThread, res.data.thread._id));
                    dispatch(setSelectedThread(res.data.thread._id));
                  })
                  .catch((err) => console.log(err));
              }}
            />
          )}
        </Header>
        {/* <div onClick={() => {
          navigate(-1);
        }} className={classNames("sticky w-[75px] cursor-pointer top-20 -ml-11 hover:ml-0 ease-in-out transition-[margin] z-[998] px-2.5 py-2 rounded-e-3xl", isDarkMode ? "bg-gray-100" : "bg-gray-300")}>
          <span>Back</span> &nbsp; <ArrowLeftOutlined />
        </div> */}
        <Layout /* className="h-[1000px]" */ className="flex-1 bg-white">
          {/* {location.pathname == '/voice-playground' && <AnimationBG />} */}
          {children}
        </Layout>
      </Layout>
      {/* <ScrollToTop smooth className="animate-bounce" /> */}
      {/* <FloatButton.BackTop /> */}
      {/* <Settings /> */}
      {/* <Drawer
        title="Basic Drawer"
        placement="left"
        closable={true}
        onClose={onClose}
        open={open}
      >
        <div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Get Advice</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Analyze Conversation</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Analyze Personality</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Follow-Up Settings</p>
          </div>
        </div>
      </Drawer> */}
      {/* <Elements stripe={stripePromise} nonce="random-nonce">
        <PayModal
          open={openPayModal}
          setOpen={() => dispatch(setOpenPayModal())}
          price={plans[0]?.price}
          planId={plans[0]?._id}
          setSuccessful={() => {}}
        />
      </Elements> */}
    </Layout>
  );
}

export default AuthLayout;
