import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingRoadmap } from './LoadingRoadmap';
import { StepModal } from './StepModal';
import { setOpenPayModal, setShowUpgradeModal } from '../../redux/thread/threadSlice';

export function RoadmapModal({ isOpen, onClose, thread }) {
  const dispatch = useDispatch();

  const isPayed = useSelector(
    (state) =>
      state.auth.user.activeSubscription &&
      (state.auth.user.activeSubscription.status === 'active' ||
        state.auth.user.activeSubscription.status === 'trialing'),
  );

  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  // const { setShowUpgradeModal } = useSidebarStore();
  const [steps, setSteps] = useState([]);
  // const { setRoadmap } = useChatStore();

  const generateSteps = async () => {
    try {
      if (!thread) return;

      // const stepOne =
      //   currentChat.analysisSteps[0]; /* await generateStepOne(messages) */

      setSteps(thread.analysisSteps);

      // Set roadmap in chat store when generated
      // setRoadmap(activeChat, {
      //   steps: steps,
      //   currentStep: 0
      // });
    } catch (error) {
      console.error('Error generating steps:', error);
    }
    if (thread.analysisSteps?.length > 0) {
      setIsLoading(false);
    }
    // setIsLoading(false);
  };

  const handleLoadingComplete = () => {
    if(isPayed) onClose();
    generateSteps();
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      if (thread.analysisSteps?.length > 0) {
        // setIsLoading(false);
        generateSteps();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setCurrentStep(1);
  }, [thread]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      closable={false}
      maskClosable={false}
      classNames={{
        content: '!p-0 !bg-transparent' + (isLoading ? ' !max-w-md mx-auto' : ''),
        mask: '!bg-black/60 backdrop-blur-sm',
      }}
      centered
    >
      {isLoading ? (
        <LoadingRoadmap key={isLoading} onComplete={handleLoadingComplete} />
      ) : currentStep <= steps.length - 1 ? (
        <StepModal
          step={steps[currentStep - 1]}
          onNext={handleNext}
          onBack={handleBack}
          onClose={onClose}
          currentStep={currentStep}
          totalSteps={steps.length}
        />
      ) : (
        <StepModal
          step={{
            id: steps.length + 1,
            title: steps[currentStep - 1].title,
            subtitle: steps[currentStep - 1].subtitle,
            image: 'https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?auto=format&fit=crop&q=80&w=400',
            imageAlt: 'New beginnings and opportunities',
            bullets: steps[currentStep - 1].bullets,
          }}
          onNext={() => {
            if (!isPayed) {
              dispatch(setShowUpgradeModal(true));
            }
            // dispatch(setOpenPayModal());
            onClose();
          }}
          onBack={handleBack}
          onClose={onClose}
          currentStep={currentStep}
          totalSteps={steps.length}
          showTrialButton={true}
        />
      )}
    </Modal>
  );
}
