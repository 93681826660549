import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { setShowUpgradeModal } from '../../../../redux/thread/threadSlice';

const LimitModal = ({ open, onClose, thread }) => {
  const dispatch = useDispatch();
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (open) {
      setStep(5);
      setTimeout(() => {
        setStep(0);
      }, 2000);
    }
  }, [open]);

  const renderScrollContent = () => {
    return (
      <>
        {thread?.memo.map((da, index) => (
          <span className="scroll-item" key={`item-${da._id}-${index}`}>{da.memory}</span>
        ))}
      </>
    );
  };

  return (
    <Modal
      centered
      classNames={{
        content: classNames('!bg-white md:mb-16 !pr-0', step === 3 && '!pt-12 !px-10'),
        mask: classNames(!siderCollapsed && '!ms-[310px]'),
        wrapper: classNames(!siderCollapsed && '!ms-[310px]'),
      }}
      width={600}
      open={open}
      onOk={() => {}}
      onCancel={() => {
        onClose();
      }}
      footer={[]}
      closable={false}
      maskClosable={true}
    >
      <h2 className="mb-0 font-bold">You've hit the daily free limit for Abby</h2>
      <p className="text-gray-600">
        More access will be available{' '}
        {dayjs().format('YYYY:MM:DD') === dayjs(thread?.messageIds[19]?.createdAt).add(1, 'day').format('YYYY:MM:DD')
          ? 'today'
          : 'tomorrow'}{' '}
        after {dayjs(thread?.messageIds[19]?.createdAt).add(1, 'day').format('h:mm A')}.
      </p>
      <div className="w-full h-24 overflow-hidden mt-6 relative">
        <div className="scroll-container scroll-left mb-4">
          {renderScrollContent()}
          {renderScrollContent()}
        </div>
        <div className="scroll-container scroll-right">
          {renderScrollContent()}
          {renderScrollContent()}
        </div>
      </div>
      <span className="inline-block mt-2 text-gray-500">+ {/* {thread?.memo.length > 12 ? thread?.memo.length - 12 : 0} */}9 more</span>
      <Flex vertical gap="small" className="max-w-xs mx-auto mt-4">
        <Button block size="large" shape="round" type="default" className="!bg-white" onClick={onClose}>
          Okay
        </Button>
        <Button type="primary" block size="large" shape="round" onClick={() => dispatch(setShowUpgradeModal(true))}>
          Upgrade to Keep Chatting
        </Button>
        <p className="m-0 text-center text-gray-500">Start Your 7 Day Free Trial</p>
      </Flex>
    </Modal>
  );
};

export default LimitModal;