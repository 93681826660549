import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getMessages, getMessagesFailure, getMessagesSuccess } from './messageSlice';

import { _getMessages } from '../../services/threadAPI';

function* getMessagesAPI(action) {
  try {
    const response = yield call(() => _getMessages(action.payload.threadId, action.payload.query));
    yield put(getMessagesSuccess({...response.data, nickName: action.payload.nickName}));
  } catch (e) {
    yield put(getMessagesFailure());
  }
}

export default function* rootSaga() {
  yield all([takeLatest(getMessages, getMessagesAPI)]);
}
