import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu, Modal, notification, Progress, Spin, Steps } from 'antd';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import { BsFillChatRightTextFill } from 'react-icons/bs';
import { FaRegEdit, FaUser } from 'react-icons/fa';
import { GoCheckCircleFill } from 'react-icons/go';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSound from 'use-sound';

import { CreditCardModal } from '../../../components/CreditCard/CreditCardModal';
import { RoadmapModal } from '../../../components/Roadmap/RoadmapModal';
import UpgradeModal from '../../../components/UpgradeModal';
import LimitModal from '../../pages/Home/Partials/LimitModal';
import WelcomebackModal from '../../pages/Home/Partials/WelcomebackModal';
import { ExclamationCircleFilled, MenuOutlined, SignalFilled, YoutubeFilled } from '@ant-design/icons';
import SafeModal from './SafeModal';
import StepModal from './StepModal';
import UserMenu from './UserMenu';
import WordModal from './WordModal';
import boopSfx from '../../../assets/sounds/effect1.mp3';
import { useSocket } from '../../../context/socket';
import { getStorage } from '../../../helpers';
import { setSiderCollapsed } from '../../../redux/app/appSlice';
import { getUser } from '../../../redux/auth/authSlice';
import {
  getMessages,
  pushMessages,
  setGenerating,
  setHasMore,
  setMessages,
  setPage,
} from '../../../redux/message/messageSlice';
import {
  getThreads,
  setOpenPayModal,
  setOpenWordModal,
  setSelectedThread,
  setShowExpiredModal,
  setShowRoadmapModal,
  setShowUpgradeModal,
} from '../../../redux/thread/threadSlice';

import { clickBullet, newThread, sendMessage } from '../../../services/threadAPI';

import './SideBar.scss';

const { Sider } = Layout;

const analysisStepTitles = ["Let's Dive Deeper", "I'll Guide You", "Let's Explore"];

const contentVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: 0.2,
    },
  },
};

function SideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const socket = useSocket();

  const { id } = useParams();

  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const user = useSelector((state) => state.auth.user);
  const isPayed = useSelector(
    (state) =>
      state.auth.user.activeSubscription &&
      (state.auth.user.activeSubscription.status === 'active' ||
        state.auth.user.activeSubscription.status === 'trialing'),
  );
  const selectedThread = useSelector((state) => state.thread.selectedThread);
  const threads = useSelector((state) => state.thread.threads);
  const thread = useSelector((state) =>
    state.thread.threads.find((_thread) => _thread._id === state.thread.selectedThread),
  );
  const showExpiredModal = useSelector((state) => state.thread.showExpiredModal);
  const showRoadmapModal = useSelector((state) => state.thread.showRoadmapModal);
  const showUpgradeModal = useSelector((state) => state.thread.showUpgradeModal);
  const openWordModal = useSelector((state) => state.thread.openWordModal);
  const messages = useSelector((state) => state.message.messages);
  const page = useSelector((state) => state.message.page);
  const limit = useSelector((state) => state.message.limit);
  const hasMore = useSelector((state) => state.message.hasMore);
  const loading = useSelector((state) => state.message.loading);
  const totalMessages = useSelector((state) => state.message.total);
  const generating = useSelector((state) => state.message.generating);
  const step = useSelector((state) => parseInt(state.message.total / 5));
  const selectedPlan = useSelector((state) => state.thread.selectedPlan);
  const openPayModal = useSelector((state) => state.thread.openPayModal);
  const [current, setCurrent] = useState('');
  const [openTour, setOpenTour] = useState(false);
  const [showAnalyzeModal, setShowAnalyzeModal] = useState(false);
  const [showWelcomebackModal, setShowWelcomebackModal] = useState(false);
  const [showSafeModal, setShowSafeModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('positive');
  const [analysisData, setAnalysisData] = useState(null);
  const [items, setItems] = useState([]);
  const [tab, setTab] = useState('chats');

  const [openKey, setOpenKey] = useState('advice');

  const [effectSound] = useSound(boopSfx);

  const toolsItems = [
    {
      key: 'advice',
      label: <span className="font-semibold text-[16px]">Get Advice</span>,
      icon: <MdOutlineTipsAndUpdates />,
      children: [
        {
          key: 'actionable',
          label: (
            <div className="flex justify-between items-center">
              <span>Actionable</span>{' '}
              {current === 'actionable' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'empathetic',
          label: (
            <div className="flex justify-between items-center">
              <span>Empathetic</span>{' '}
              {current === 'empathetic' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'perspectiveShifting',
          label: (
            <div className="flex justify-between items-center">
              <span>Perspective-Shifting</span>{' '}
              {current === 'perspectiveShifting' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'thoughtProvoking',
          label: (
            <div className="flex justify-between items-center">
              <span>Thought Provoking</span>{' '}
              {current === 'thoughtProvoking' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'philosophical',
          label: (
            <div className="flex justify-between items-center">
              <span>Philosophical</span>{' '}
              {current === 'philosophical' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'selfReflection',
          label: (
            <div className="flex justify-between items-center">
              <span>Self-Reflection</span>{' '}
              {current === 'selfReflection' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'spiritual',
          label: (
            <div className="flex justify-between items-center">
              <span>Spiritual</span>{' '}
              {current === 'spiritual' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
      ],
    },
    {
      key: 'analysis',
      label: <span className="font-semibold text-[16px]">Analysis</span>,
      icon: <SignalFilled />,
      children: [
        {
          key: 'worldview',
          label: (
            <div className="flex justify-between items-center">
              <span>Worldview Analysis</span>{' '}
              {current === 'worldview' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'decisionMaking',
          label: (
            <div className="flex justify-between items-center">
              <span>Decision Making</span>{' '}
              {current === 'decisionMaking' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'habits',
          label: (
            <div className="flex justify-between items-center">
              <span>Habits Analysis</span>{' '}
              {current === 'habits' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'speculative',
          label: (
            <div className="flex justify-between items-center">
              <span>Speculative Analysis</span>{' '}
              {current === 'speculative' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'cognitiveDistortion',
          label: (
            <div className="flex justify-between items-center">
              <span>Cognitive Distortion Analysis</span>{' '}
              {current === 'cognitiveDistortion' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'relationship',
          label: (
            <div className="flex justify-between items-center">
              <span>Relationship Analysis</span>{' '}
              {current === 'relationship' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'intent',
          label: (
            <div className="flex justify-between items-center">
              <span>Intent Analysis</span>{' '}
              {current === 'intent' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'valuesBelief',
          label: (
            <div className="flex justify-between items-center">
              <span>Values and Belief Analysis</span>{' '}
              {current === 'valuesBelief' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'stressCoping',
          label: (
            <div className="flex justify-between items-center">
              <span>Stress and Coping Analysis</span>{' '}
              {current === 'stressCoping' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
      ],
    },
    {
      key: 'personality',
      label: <span className="font-semibold text-[16px]">Personality</span>,
      icon: <FaUser />,
      children: [
        {
          key: 'personalityArchetype',
          label: (
            <div className="flex justify-between items-center">
              <span>Personality Archetype Analysis</span>{' '}
              {current === 'personalityArchetype' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'psychodynamic',
          label: (
            <div className="flex justify-between items-center">
              <span>Psychodynamic Analysis</span>{' '}
              {current === 'psychodynamic' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'attachmentStyle',
          label: (
            <div className="flex justify-between items-center">
              <span>Attachment Style Analysis</span>{' '}
              {current === 'attachmentStyle' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'moralEthicalReasoning',
          label: (
            <div className="flex justify-between items-center">
              <span>EmpatheticMoral and Ethical Reasoninspang Analysis</span>{' '}
              {current === 'moralEthicalReasoning' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'valuesMotivational',
          label: (
            <div className="flex justify-between items-center">
              <span>Values and Motivational Analysis</span>{' '}
              {current === 'valuesMotivational' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'emotionalIntelligence',
          label: (
            <div className="flex justify-between items-center">
              <span>EmpatheticEmotional Intelligence (EQ) Analysis</span>{' '}
              {current === 'emotionalIntelligence' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'psychologicalNeeds',
          label: (
            <div className="flex justify-between items-center">
              <span>EmpatheticShadow Personality Analysis Traits</span>{' '}
              {current === 'psychologicalNeeds' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'shadowPersonality',
          label: (
            <div className="flex justify-between items-center">
              <span>Psychological Needs Analysis</span>{' '}
              {current === 'shadowPersonality' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'socialIdentity',
          label: (
            <div className="flex justify-between items-center">
              <span>Social Identity Analysis</span>{' '}
              {current === 'socialIdentity' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'situationalPersonality',
          label: (
            <div className="flex justify-between items-center">
              <span>Situational Personality Analysis</span>{' '}
              {current === 'situationalPersonality' && (
                <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />
              )}
            </div>
          ),
        },
      ],
    },
    {
      key: 'content',
      label: <span className="font-semibold text-[16px]">Related Content</span>,
      icon: <YoutubeFilled />,
      children: [
        {
          key: 'blogPosts',
          label: (
            <div className="flex justify-between items-center">
              <span>Blog Posts</span>{' '}
              {current === 'blogPosts' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'videos',
          label: (
            <div className="flex justify-between items-center">
              <span>Videos</span>{' '}
              {current === 'videos' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'podcasts',
          label: (
            <div className="flex justify-between items-center">
              <span>Podcasts</span>{' '}
              {current === 'podcasts' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
        {
          key: 'researchArticles',
          label: (
            <div className="flex justify-between items-center">
              <span>Research Articles</span>{' '}
              {current === 'researchArticles' && <Spin size="small" className="[&_.ant-spin-dot-item]:bg-white" />}
            </div>
          ),
        },
      ],
    },
  ];

  const onToolsClick = (e) => {
    console.log('click ', e.key);
    if (current) {
      return notification.info({
        description: 'Please wait for the current analysis to finish.',
      });
    }
    if (messages.length < 6) {
      return notification.info({
        description: `You don't have enough messages to analyze. Please make more chats.`,
      });
    }
    if (thread.status === 1) {
      return dispatch(setShowExpiredModal(true));
    }
    if (thread.back === 1) {
      return;
    }
    setCurrent(e.key);
    // setOpenModal(`/data/${e.key}`);
    // navigate(`/data/${e.key}`);
    socket.emit('analysisMessage', { dataKey: e.key, threadId: selectedThread });
    if (isMobile) {
      dispatch(setSiderCollapsed(true));
    }
  };

  const handleNewChat = () => {
    newThread()
      .then((res) => {
        // console.log(res.data);
        dispatch(setPage(1));
        dispatch(setHasMore(true));
        dispatch(setMessages([]));
        dispatch(getThreads());
        navigate(location.pathname.replace(selectedThread, res.data.thread._id));
        dispatch(setSelectedThread(res.data.thread._id));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setShowSafeModal(!!getStorage('registered'));
    dispatch(getThreads());
  }, [dispatch]);

  useEffect(() => {
    if (socket && current) {
      socket.on('_analysisMessage', (data) => {
        console.log('_analysisMessage', data);
        setCurrent('');
      });
    }
    if (socket) {
      socket.on('analysisMessage', (data) => {
        dispatch(setGenerating(true));
        dispatch(
          pushMessages({
            data: [
              {
                role: 'assistant',
                content: '',
              },
            ],
            isPush: true,
          }),
        );
      });
    }
    return () => {
      if (socket) {
        socket.off('_analysisMessage');
        socket.off('analysisMessage');
      }
    };
  }, [socket, current, dispatch]);

  useEffect(() => {
    if (id && id !== selectedThread) {
      dispatch(setSelectedThread(id));
    } else if (!id && selectedThread) {
      if (location.pathname.includes('playground')) {
        navigate(selectedThread);
      }
    }
  }, [id, selectedThread, dispatch, navigate, location]);

  useEffect(() => {
    if (threads.length > 0) {
      setItems(
        threads.map((thread) => {
          if (thread.analysisSteps?.length > 0) {
            return {
              key: thread._id,
              label: <p className="m-0 max-w-full font-bold text-[16px] truncate">{thread.title || 'New chat'}</p>,
              icon: <BsFillChatRightTextFill />,
              // children: thread.subTitles.map((subTitle, index) => {
              //   return {
              //     key: thread._id + index,
              //     icon: (
              //       <span className="flex justify-center items-center bg-white rounded-full w-[18px] h-[18px] font-bold !text-black">
              //         {index + 1}
              //       </span>
              //     ),
              //     label: (
              //       <p className="m-0 max-w-full font-bold text-[14px] truncate">{subTitle.title || 'New chat'}</p>
              //     ),
              //     onClick: async () => {
              //       // console.log('----', subTitle.desc);
              //       if (thread.status === 1)
              //         return notification.warning({
              //           message: 'Please upgrade to PRO.',
              //         });
              //       if (generating) return;
              //       dispatch(
              //         setMessages([
              //           {
              //             role: 'assistant',
              //             content: subTitle.desc,
              //             animate: true,
              //             _id: Date.now() + 'assistant',
              //           },
              //           ...messages,
              //         ]),
              //       );
              //       await sendMessage(thread._id, { prompt: subTitle.desc });
              //     },
              //   };
              // }),
              children: /* thread.analysisSteps
                .slice(0, 3).map((step, index) => ({
                  ...step,
                  index: index,
                }))
                .filter((step) => step.step < 3).length > 0 &&  */ thread.analysisSteps
                .slice(0, 3)
                .map((step, index) => ({
                  ...step,
                  index: index,
                }))
                .filter((step) => step.step < 3)
                .map((step, index) => ({
                  key: thread._id + index,
                  label: (
                    <div className="leading-5">
                      <p className="m-0 font-bold text-[14px]">{analysisStepTitles[step.index]}</p>
                      <motion.p
                        className="m-0 text-gray-600 whitespace-pre-line"
                        variants={contentVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key={step.bullets[step.step]}
                      >
                        {step.bullets[step.step]}
                      </motion.p>
                    </div>
                  ),
                  // icon: <BsFillChatRightTextFill />,
                  onClick: async () => {
                    if (generating) return;
                    if (thread.status === 1) return dispatch(setShowExpiredModal(true));
                    dispatch(setGenerating(true));
                    clickBullet(selectedThread, { index: step.index }).then(() => {
                      dispatch(getThreads());
                      dispatch(
                        setMessages([
                          {
                            role: 'assistant',
                            content: '',
                            _id: Date.now() + 'assistant',
                          },
                          {
                            role: 'user',
                            content: `Can we explore "${step.bullets[step.step]}" for my current situation.`,
                            _id: Date.now() + 'user',
                          },
                          ...messages,
                        ]),
                      );
                      socket.emit('sendMessage', {
                        prompt: `Can we explore "${step.bullets[step.step]}" for my current situation.`,
                        stream: true,
                        threadId: thread._id,
                        text: true,
                        auto: true,
                      });
                    });
                  },
                })),
            };
          }
          return {
            key: thread._id,
            label: <p className="m-0 font-bold text-[16px]">{thread.title || 'New chat'}</p>,
            icon: <BsFillChatRightTextFill />,
            children: [
              {
                key: thread._id + '0',
                className: 'stepper-view-menu',
                label: (
                  <>
                    <Steps
                      direction="vertical"
                      size="small"
                      current={step}
                      className={classNames(
                        'h-full min-h-72 analysis-steps',
                        `step-${step + 1} percent-${(totalMessages % 5) * 20}`,
                      )}
                      items={[
                        {
                          title: (
                            <span className="font-bold" /* ref={ref1} */>
                              Listening ({step === 0 ? parseInt(totalMessages % 5) : 5}/5)
                            </span>
                          ),
                        },
                        {
                          title: (
                            <span className="font-bold" /* ref={ref2} */>
                              Analyzing ({step === 1 ? parseInt(totalMessages % 5) : step > 1 ? 5 : 0}/5)
                            </span>
                          ),
                        },
                        {
                          title: (
                            <span className="font-bold" /* ref={ref3} */>
                              Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </>
                ),
              },
            ],
          };
        }),
      );
      if (!selectedThread) {
        dispatch(setSelectedThread(threads[0]._id));
      }
    }
  }, [threads, selectedThread, totalMessages, step, messages, dispatch, generating]);

  // useEffect(() => {
  //   if (siderCollapsed) {
  //     setItems([]);
  //   } else {
  //     setTimeout(() => {
  //       setItems(threads.map((thread) => {
  //         if (thread.subTitles?.length > 0) {
  //           return {
  //             key: thread._id,
  //             label: <p className='m-0 max-w-full font-bold text-[16px] truncate'>{thread.title || "New chat"}</p>,
  //             icon: <BsFillChatRightTextFill />,
  //             children: thread.subTitles.map((subTitle, index) => {
  //               return {
  //                 key: thread._id + index,
  //                 icon: <span className='flex justify-center items-center bg-white rounded-full w-[18px] h-[18px] font-bold !text-black'>{index + 1}</span>,
  //                 label: <p className='m-0 max-w-full font-bold text-[14px] truncate'>{subTitle.title || "New chat"}</p>,
  //                 onClick: async () => {
  //                   // console.log('----', subTitle.desc);
  //                   dispatch(setMessages([
  //                     {
  //                       role: 'assistant',
  //                       content: subTitle.desc,
  //                       animate: true,
  //                     },
  //                     ...messages,
  //                   ]));
  //                   await sendMessage(thread._id, { prompt: subTitle.desc });
  //                 }
  //               };
  //             }),
  //           };
  //         }
  //         return {
  //           key: thread._id,
  //           label: <p className='m-0 font-bold text-[16px]'>{thread.title || "New chat"}</p>,
  //           icon: <BsFillChatRightTextFill />,
  //           children: [
  //             {
  //               key: thread._id + '0',
  //               className: 'stepper-view-menu',
  //               label: <>
  //                 <Steps
  //                   direction="vertical"
  //                   size="small"
  //                   current={step}
  //                   className={classNames('h-full min-h-72 analysis-steps', `step-${step + 1} percent-${(totalMessages % 5) * 20}`)}
  //                   items={[
  //                     {
  //                       title: <span className="font-bold" /* ref={ref1} */>Listening ({step == 0 ? parseInt(totalMessages % 5) : 5}/5)</span>,
  //                     },
  //                     {
  //                       title: <span className="font-bold" /* ref={ref2} */>Analyzing ({step == 1 ? parseInt(totalMessages % 5) : (step > 1 ? 5 : 0)}/5)</span>,
  //                     },
  //                     {
  //                       title: <span className="font-bold" /* ref={ref3} */>Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}</span>,
  //                     },
  //                   ]}
  //                 />
  //               </>
  //             },
  //           ]
  //         };
  //       }));
  //     }, [150]);
  //   }
  // }, [siderCollapsed]);

  useEffect(() => {
    if (socket) {
      socket.on('reasonAnswer', (data) => {
        // console.log(data);
        if (selectedThread === data.threadId) {
          dispatch(
            setMessages([
              {
                role: 'assistant',
                reason: data.content,
                _id: data._id,
              },
              ...messages.slice(1),
            ]),
          );
        }
      });

      socket.on('answer', (data) => {
        // console.log(data);
        if (data.refMemo || data.reason) {
          if (selectedThread === data.threadId && data.reason) {
            let index = messages.findIndex((message) => message._id === data._id);
            if (index < 0) return;
            let tmp = [...messages];
            tmp.splice(index, 1, {
              ...messages[index],
              reason: data.reason,
              refMemo: data.refMemo,
            });
            dispatch(setMessages(tmp));
          }
        } else if (data.end) {
          dispatch(setGenerating(false));
        } else {
          if (selectedThread === data.threadId) {
            dispatch(
              setMessages([
                {
                  ...messages[0],
                  role: 'assistant',
                  content: data.content,
                  _id: data._id,
                },
                ...messages.slice(1),
              ]),
            );
          }
        }
      });

      socket.on('limitReached', () => {
        dispatch(getThreads());
        dispatch(setShowRoadmapModal(true));
        if (isPayed) {
          console.log('---limitReached----');
          // dispatch(setShowExpiredModal(true));
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('answer');
        socket.off('reasonAnswer');
        socket.off('limitReached');
      }
    };
  }, [socket, messages, isPayed, dispatch, selectedThread]);

  useEffect(() => {
    if (socket) {
      socket.on('analysisData', (data) => {
        effectSound();
        setAnalysisData(data);
        setShowAnalyzeModal(true);
      });
      socket.on('analyzed_thread', (data) => {
        // console.log("analyzed_thread", data);
        effectSound();
        dispatch(getThreads());
      });
      socket.on('SUBSCRIPTION_EVENT', (data) => {
        console.log('SUBSCRIPTION_EVENT', data);
        dispatch(getUser());
        dispatch(getThreads());
        dispatch(setMessages([]));
        dispatch(setPage(1));
        dispatch(setHasMore(true));
      });
    }

    return () => {
      if (socket) {
        socket.off('SUBSCRIPTION_EVENT');
        socket.off('analysisData');
        socket.off('analyzed_thread');
      }
    };
  }, [socket, effectSound, dispatch]);

  useEffect(() => {
    // console.log(thread);
    if (id === thread?._id) {
      if (thread && thread.back === 1 && !isPayed) {
        setShowWelcomebackModal(true);
      } else if (thread && thread.status === 1 && !isPayed) {
        // if (!showUpgradeModal) dispatch(setShowExpiredModal(true));
      } else if (thread && isPayed) {
        // dispatch(setShowExpiredModal(false));
        setShowWelcomebackModal(false);
      }
    }
  }, [threads, isPayed, dispatch, id, showUpgradeModal]);

  useEffect(() => {
    if (!loading && selectedThread && hasMore) {
      console.log('loading...');
      dispatch(getMessages({ threadId: selectedThread, query: { page, limit }, nickName: user.nickName }));
    }
  }, [selectedThread, page, user]);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100%',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: 'unset',
        zIndex: 999,
      }}
      breakpoint="md"
      onBreakpoint={(broken) => {
        if (broken) {
          dispatch(setSiderCollapsed(true));
        } else {
          dispatch(setSiderCollapsed(false));
        }
      }}
      className={classNames(!isPayed && '', 'sidebar !bg-transparent')}
      collapsedWidth={0}
      width={isMobile ? '100%' : 310}
      collapsed={siderCollapsed}
      collapsible
      trigger={null}
      onClick={(e) => {
        if ($(e.target).hasClass('ant-layout-sider-children')) {
          dispatch(setSiderCollapsed(true));
        }
      }}
    >
      <div className={classNames('inline-flex flex-col h-full bg-gray-100 w-[310px]')}>
        <div
          className={classNames(
            'h-16 flex items-center justify-between sticky top-0 z-[9999] bg-gray-100 shadow-sm',
            isMobile ? 'justify-between' : '',
          )}
        >
          <Button
            icon={<MenuOutlined />}
            type="text"
            className="ml-2"
            onClick={() => {
              dispatch(setSiderCollapsed(!siderCollapsed));
            }}
          />
          {/* {isMobile && <div className='flex items-center gap-4 mr-2'>
            <Link to="/playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/playground');
            }}>
              Text Chat
            </Link>
            <Link to="/voice-playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/voice-playground');
            }}>
              Voice Chat
            </Link>
            <UserMenu />
          </div>} */}
        </div>
        {isPayed && (
          <div className="flex justify-center items-center border-0 border-gray-200 py-1 border-b border-solid">
            <div className="flex justify-center items-center gap-2 bg-zinc-200 mt-2 mb-1 px-4 py-1.5 rounded-3xl">
              <Button
                className={classNames(
                  tab === 'chats'
                    ? '!bg-white !shadow !text-blue-600'
                    : '!text-gray-500 bg-transparent border-0 shadow-none hover:!bg-white hover:!shadow hover:!text-blue-600',
                )}
                onClick={() => setTab('chats')}
                shape="round"
              >
                Chats
              </Button>
              <Button
                className={classNames(
                  tab === 'tools'
                    ? '!bg-white !shadow !text-blue-600'
                    : '!text-gray-500 bg-transparent border-0 shadow-none hover:!bg-white hover:!shadow hover:!text-blue-600',
                )}
                onClick={() => {
                  return setTab('tools');
                  // if (isPayed) {
                  // }
                  // message.error('Please upgrade to PRO to use tools.');
                }}
                shape="round"
              >
                Tools
              </Button>
            </div>
          </div>
        )}
        <div className="flex-1">
          {!isPayed && messages.length > 0 && (
            <>
              {/* <Progress className='[&_.ant-progress-outer]:inline-flex leading-[0]' status="active" strokeLinecap="butt" percent={totalMessages / 40 * 100} showInfo={false} size={["100%", 10]} strokeColor="#1b0035" trailColor="#fff" /> */}

              <div className="gap-4 p-6 min-h-96 text-center">
                {/* {config.steps.map((_step, index) => <div key={index} className={classNames("rounded-lg border border-gray-400 border-solid py-2 text-lg flex items-center justify-center gap-2 text-gray-400", step === index ? 'cursor-wait' : 'cursor-default')}>
                {step === index && <Spin size='small' />} <span className={classNames(step === index && "animate-shine")} style={{ animationDuration: "10s" }}>{_step}</span>
              </div>)} */}
                <Steps
                  direction="vertical"
                  size="small"
                  current={step}
                  className={classNames(
                    'h-full min-h-96 analysis-steps',
                    `step-${step + 1} percent-${(totalMessages % 5) * 20}`,
                  )}
                  items={[
                    {
                      title: (
                        <span className="font-bold" /* ref={ref1} */>
                          Listening ({step === 0 ? parseInt(totalMessages % 5) : 5}/5)
                        </span>
                      ),
                    },
                    {
                      title: (
                        <span className="font-bold" /* ref={ref2} */>
                          Analyzing ({step === 1 ? parseInt(totalMessages % 5) : step > 1 ? 5 : 0}/5)
                        </span>
                      ),
                    },
                    {
                      title: (
                        <span className="font-bold" /* ref={ref3} */>
                          Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}
                        </span>
                      ),
                    },
                  ]}
                />
                {/* <div className='text-gray-500 text-lg'>
                Abby will ask questions to learn about your specific situation.
              </div> */}
              </div>
            </>
          )}
          {!siderCollapsed &&
            isPayed &&
            (tab === 'chats' ? (
              <>
                <Menu
                  // onClick={onClick}
                  className="w-full sidebar-chat sidebar-menu"
                  openKeys={[selectedThread]}
                  selectedKeys={[selectedThread]}
                  mode="inline"
                  items={items}
                  onSelect={({ key }) => {
                    // if (openKeys[openKeys.length - 1]) {
                    // }
                    if (key.includes(selectedThread)) return;
                    dispatch(setPage(1));
                    dispatch(setHasMore(true));
                    dispatch(setMessages([]));
                    dispatch(setSelectedThread(key));
                    // navigate(location.pathname.replace(selectedKeys[0], selectedKeys[1]));
                    navigate(`/playground/${key}`);
                  }}
                  onOpenChange={(openKeys) => {
                    if (openKeys[openKeys.length - 1]) {
                      dispatch(setPage(1));
                      dispatch(setHasMore(true));
                      dispatch(setMessages([]));
                      dispatch(setSelectedThread(openKeys[openKeys.length - 1]));
                      navigate(location.pathname.replace(openKeys[0], openKeys[1]));
                    }
                  }}
                />
                <div className="p-4 h-16 text-center">
                  <Button
                    className="inline-flex items-center"
                    icon={<FaRegEdit />}
                    type="primary"
                    onClick={handleNewChat}
                  >
                    New Chat
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Menu
                  onClick={onToolsClick}
                  className="w-full sidebar-menu"
                  openKeys={[openKey]}
                  selectedKeys={[current]}
                  mode="inline"
                  items={toolsItems}
                  onOpenChange={(openKeys) => {
                    if (openKeys[openKeys.length - 1]) {
                      setOpenKey(openKeys[openKeys.length - 1]);
                    }
                  }}
                />
              </>
            ))}
        </div>
        <div className="border-0 border-gray-300 p-2 border-t border-solid h-16">
          {/* {(user.activeSubscription?.status !== 'active' && user.activeSubscription?.status !== 'trialing') && <div className="py-4 text-center">
            <Button
              size='large'
              onClick={setOpenModal}
              className="w-4/5 font-semibold"
            >{user.pm_last_four ? 'Upgrade to PRO' : '7 Day Free Trial'}</Button>
          </div>} */}
          <UserMenu />
        </div>
      </div>
      <StepModal open={openTour} setOpen={setOpenTour} />
      <Modal
        centered
        classNames={{ content: '!bg-white' }}
        width={500}
        open={showAnalyzeModal}
        onOk={() => {}}
        onCancel={() => {}}
        footer={[]}
        closable={false}
        maskClosable={false}
      >
        <div className="text-black text-center">
          <Progress
            strokeLinecap="butt"
            percent={40}
            showInfo={false}
            size={['100%', 12]}
            strokeColor="#1b0035"
            trailColor="#e2d0a1"
          />
          <h1 className="my-4 font-extrabold text-center text-primary">Your First Analysis!</h1>
          <p className="bg-primary my-4 p-3 rounded-md text-[16px] text-gray-100">
            <b>Abby:</b> I did a quick analysis on our conversation so far...
          </p>
          <div className="my-2 md:my-4">
            <Button
              className={classNames('m-1 rounded-none', selectedTab !== 'positive' && 'bg-[#eee]')}
              size=""
              onClick={() => setSelectedTab('positive')}
            >
              Positive Traits
            </Button>
            <Button
              className={classNames('m-1 rounded-none', selectedTab !== 'areas_for_improvement' && 'bg-[#eee]')}
              size=""
              onClick={() => setSelectedTab('areas_for_improvement')}
            >
              Areas of Improvement
            </Button>
          </div>
          <div className="my-2 md:my-4 text-left">
            {analysisData &&
              analysisData[selectedTab].map((data, index) => (
                <div key={index} className="flex items-center">
                  <GoCheckCircleFill
                    className="w-14"
                    fontSize={30}
                    color={selectedTab === 'positive' ? '#27cf27' : '#DB9516'}
                  />
                  <p
                    className="flex-1 my-2 md:my-4"
                    dangerouslySetInnerHTML={{ __html: data.replace('**', '<b>').replace('**', '</b>') }}
                  ></p>
                </div>
              ))}
          </div>
          <Button
            className="px-20 font-bold"
            type="primary"
            size="large"
            onClick={() => {
              setShowAnalyzeModal(false);
            }}
          >
            Keep Chatting
          </Button>
        </div>
      </Modal>
      <LimitModal open={showExpiredModal} onClose={() => dispatch(setShowExpiredModal(false))} thread={thread} />
      <RoadmapModal isOpen={showRoadmapModal} onClose={() => dispatch(setShowRoadmapModal(false))} thread={thread} />
      <UpgradeModal isOpen={showUpgradeModal} onClose={() => dispatch(setShowUpgradeModal(false))} />
      <CreditCardModal
        isOpen={openPayModal}
        onClose={() => dispatch(setOpenPayModal(false))}
        selectedPlan={selectedPlan}
      />
      <WelcomebackModal
        open={showWelcomebackModal}
        onClose={() => {
          handleNewChat();
          setShowWelcomebackModal(false);
        }}
        thread={thread}
      />
      <SafeModal
        open={showSafeModal}
        onClose={() => {
          setShowSafeModal(false);
          setOpenTour(true);
        }}
      />
      <WordModal
        open={openWordModal}
        onClose={() => {
          dispatch(setOpenWordModal(false));
        }}
      />
    </Sider>
  );
}

export default SideBar;
