import React from 'react';
import { Button, Flex, Modal } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { setShowUpgradeModal } from '../../../../redux/thread/threadSlice';

function WelcomebackModal({ open, onClose, thread }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const setOpenModal = () => {
    if (
      user.activeSubscription &&
      user.activeSubscription.status !== 'active' &&
      user.activeSubscription.status !== 'trialing'
    ) {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setShowUpgradeModal(true));
        },
      });
    } else {
      dispatch(setShowUpgradeModal(true));
    }
  };

  const renderScrollContent = () => {
    return (
      <>
        {thread?.memo.map((da, index) => (
          <span className="scroll-item" key={`item-${index}`}>{da.memory}</span>
        ))}
      </>
    );
  };

  return (
    <Modal
      centered
      classNames={{ content: '!bg-white md:mb-16 !pr-0', mask: '!blur-md', wrapper: '' }}
      width={600}
      open={open}
      onOk={() => {}}
      onCancel={() => {}}
      footer={[]}
      closable={false}
      maskClosable={false}
    >
      <div className="text-black">
        <h2 className="mt-4 mb-0 font-bold text-left text-primary">Keep Your Memories?</h2>
        <p className="mb-2 italic text-gray-500 leading-tight">
          Start a new free chat or upgrade to continue where you left off.
        </p>

        <div className="w-full h-24 overflow-hidden mt-6 relative">
          <div className="scroll-container scroll-left mb-4">
            {renderScrollContent()}
            {renderScrollContent()}
          </div>
          <div className="scroll-container scroll-right">
            {renderScrollContent()}
            {renderScrollContent()}
          </div>
        </div>
        <span className="inline-block mt-2 text-gray-500">+ {/* {thread?.memo.length > 12 ? thread?.memo.length - 12 : 0} */}9 more</span>

        <Flex vertical className="mt-6 max-w-xs mx-auto">
          <Button shape="round" className="m-2 font-bold rounded-sm !bg-white" size="large" onClick={() => onClose()}>
            New Chat
          </Button>
          <Button
            shape="round"
            type="primary"
            className="m-2 font-bold rounded-sm"
            size="large"
            onClick={() => {
              setOpenModal();
            }}
          >
            Upgrade to Keep Your Memories
          </Button>
          <p className="m-0 text-center text-gray-500">Start Your 7 Day Free Trial</p>
        </Flex>
      </div>
    </Modal>
  );
}

export default WelcomebackModal;