import React from 'react'
import { motion } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';

export function RoadmapSteps({ currentStep, totalSteps }) {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-black/50 backdrop-blur-sm px-6 py-3 rounded-lg border border-white/20 flex items-center justify-center">
      <div className="flex items-center gap-3">
        {[...Array(totalSteps)].map((_, index) => {
          const stepNumber = index + 1;
          const isComplete = stepNumber < currentStep;
          const isCurrent = stepNumber === currentStep;
          
          return (
            <div
              key={stepNumber}
              className="flex items-center justify-center"
            >
              <motion.div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  isCurrent 
                    ? 'bg-white text-black' 
                    : isComplete
                    ? 'bg-green-500 text-white'
                    : 'bg-white/20 text-white/60'
                }`}
                animate={isCurrent ? { scale: [1, 1.1, 1] } : {}}
                transition={{ duration: 1, repeat: Infinity }}
              >
                {isComplete ? (
                  <CheckCircle2 className="w-5 h-5" />
                ) : (
                  <span className="font-bold">{stepNumber}</span>
                )}
              </motion.div>

              {stepNumber < totalSteps && (
                <div 
                  className={`w-6 h-0.5 mx-1 ${
                    stepNumber < currentStep 
                      ? 'bg-green-500' 
                      : 'bg-white/20'
                  }`}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}