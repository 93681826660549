import { Fragment } from 'react';
import { Modal } from 'antd';
import { motion } from 'framer-motion';
import { X, DollarSign, Clock, MessageSquare, Shield, Heart, CheckCircle2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from './ui/Button';
import { setOpenPayModal, setSelectedPlan } from '../redux/thread/threadSlice';

// import { useSidebar } from "../layout/sidebar/hooks/useSidebar";
const comparisonItems = [
  {
    traditional: '$150-300 per session',
    abby: '$19.99/month unlimited',
    icon: DollarSign,
    highlight: 'Save up to $1,150/month',
  },
  {
    traditional: '1 hour',
    abby: 'Available 24/7',
    icon: Clock,
    highlight: 'Get help whenever you need it',
  },
  {
    traditional: 'Wait days/weeks between sessions',
    abby: 'Instant support anytime',
    icon: MessageSquare,
    highlight: 'No more waiting for appointments',
  },
  {
    traditional: 'Fear of judgment',
    abby: '100% private & anonymous',
    icon: Shield,
    highlight: 'Share freely without fear',
  },
  {
    traditional: 'Limited availability',
    abby: 'Always here for you',
    icon: Heart,
    highlight: 'Support that fits your schedule',
  },
];

export default function UpgradeModal({ isOpen, onClose }) {
  const plans = [
    {
      id: 'annual',
      name: 'Annual',
      price: '$14.99',
      period: 'yearly ($179.88)',
      savings: 'Save 25%',
      popular: true,
    },
    {
      id: 'monthly',
      name: 'Monthly',
      price: '$19.99',
      period: 'monthly',
      popular: false,
    },
  ];
  const dispatch = useDispatch();
  const { selectedPlan } = useSelector((state) => state.thread);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={950}
      closable={false}
      classNames={{
        content: '!p-0 !bg-transparent m-2 md:m-8',
        mask: '!bg-black/60 backdrop-blur-sm',
      }}
      centered
    >
      <div className="relative w-full max-w-4xl bg-black/30 backdrop-blur-md rounded-2xl p-4 md:p-6 shadow-2xl border border-white/10 my-4 md:my-0">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white/60 hover:text-white transition-colors bg-transparent border-none cursor-pointer"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="mb-6 text-center">
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-2">Start Your 7 Day Free Trial</h2>
          <p className="text-white/70">AI Therapy at a fraction of the cost.</p>
        </div>

        {/* Savings Badge */}
        <div className="absolute -top-3 right-4 md:right-12 hidden md:block">
          <div className="bg-gradient-to-br from-green-500 to-emerald-500 px-4 py-2 rounded-full shadow-lg transform rotate-12 -translate-x-8">
            <div className="text-sm font-bold text-white whitespace-nowrap">Save $785+ / month</div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Comparison Section - Left Side */}
          <div className="flex-1 overflow-x-hidden bg-white/[0.03] backdrop-blur-sm rounded-xl p-4 border border-white/10">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="text-center p-2">
                <h3 className="text-white/60 text-sm font-medium">Traditional Therapy</h3>
              </div>
              <div className="text-center p-2">
                <h3 className="text-white text-sm font-medium">Abby</h3>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {comparisonItems.map((item, index) => (
                <Fragment key={index}>
                  <div className="p-2 md:p-3 rounded-lg bg-white/[0.08] border border-white/10">
                    <div className="flex items-center gap-2 mb-1">
                      <item.icon className="w-4 h-4 text-white/60" />
                      <span className="text-xs md:text-sm text-white/80">{item.traditional}</span>
                    </div>
                  </div>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="relative p-2 md:p-3 rounded-lg bg-white/[0.15]"
                  >
                    {/* Animated glow effect */}
                    <motion.div
                      className="absolute inset-0 bg-gradient-to-r from-emerald-500/0 via-emerald-500/20 to-emerald-500/0 rounded-lg"
                      animate={{
                        opacity: [0, 0.5, 0],
                        x: ['-100%', '100%'],
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatDelay: index * 1,
                      }}
                    />
                    <div className="flex items-center gap-2 mb-1">
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: index * 0.2 }}>
                        <CheckCircle2 className="w-4 h-4 text-green-400" />
                      </motion.div>
                      <span className="text-xs md:text-sm text-white">{item.abby}</span>
                    </div>
                    <motion.p
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.3 }}
                      className="text-[10px] md:text-xs text-emerald-400 ml-6"
                    >
                      {item.highlight}
                    </motion.p>
                  </motion.div>
                </Fragment>
              ))}
            </div>
          </div>
          {/* Plan Selection and Button - Right Side */}
          <div className="w-full md:w-80 flex flex-col justify-center shrink-0">
            <div className="space-y-3 mb-6">
              {plans.map((plan, index) => (
                <label
                  key={index}
                  className={`flex items-center gap-3 p-3 rounded-lg border cursor-pointer ${
                    selectedPlan === plan.id ? 'border-white/20 bg-white/10' : 'border-white/10 bg-white/5'
                  }`}
                >
                  <input
                    type="radio"
                    name="plan"
                    value={plan.id}
                    checked={selectedPlan === plan.id}
                    onChange={(e) => {
                      dispatch(setSelectedPlan(e.target.value));
                    }}
                    className="w-4 h-4"
                  />
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className={`font-medium ${selectedPlan === plan.id ? 'text-white' : 'text-white/50'}`}>
                        {plan.price} / month
                      </span>
                      {plan.savings && <span className="text-xs text-emerald-400 font-medium">Save 25%</span>}
                    </div>
                    <div className={`text-sm ${selectedPlan === plan.id ? 'text-white/60' : 'text-white/40'}`}>
                      Billed {plan.period}
                    </div>
                  </div>
                </label>
              ))}
            </div>

            {/* Start Trial Button */}
            <div className="flex flex-col items-center">
              <Button
                className="w-full bg-white text-black py-3 rounded-lg font-semibold hover:bg-white/90 transition-colors mb-4 border-none cursor-pointer"
                onClick={() => {
                  dispatch(setOpenPayModal(true));
                  // dispatch(setShowUpgradeModal(true));
                }}
              >
                Start 7-Day Free Trial
              </Button>

              <div className="text-center space-y-1">
                <p className="text-white/60 text-sm">No payment due now • Cancel anytime</p>
                <p className="text-white/40 text-xs">
                  Only ${selectedPlan === 'annual' ? '14.99' : '19.99'}
                  /month after trial
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
